<template>
<div>
<myheader></myheader>
<div class="banner">
		<div id="slider">
		    
			<el-carousel height="400px" :interval="8000">
				<el-carousel-item v-for="(item,i) in imgs" :key="i">
					<img :src="item.attachment" class="pic" @click="tourl2(item)">
				</el-carousel-item>
			</el-carousel>
		</div>
	</div>
	<div class="main">
		<h3 style="margin-bottom:15px;color:#ff7c00">领队风采</h3>
		<div class="imglist">
			<div class="first" @click="tourl('/guidelist')" v-if="first">
				<div class="firstimg">
					<img :src="first.photo">
				</div>
				<img src="../../assets/shot/byzx.png" class="tipimg">				
				<p>{{first.real_name}}</p>
				<div class="firstbg"></div>
			</div>

			<div class="otherimg ">
				<div class="imgbg" v-for="(item,i) in teamerlist" :key="i" @click="tourl('/guidelist')">
					<img :src="item.photo" style="height:140px">
					<p>{{item.real_name}}</p>
					<div class="bg"></div>
				</div>
				<div class="last">
					<div @click="tourl('/guidelist')">
						<p><img src="../../assets/shot/more.png" style="width:30px"></p>
						<p>查看更多</p>
					</div>

				</div>
			</div>
		</div>

		<div class="trivalwrite">
			<div class="chose">
				<div :class="sign==1 ? 'active' : ''"  @click="chose(1)">推荐游记</div>
				<div :class="sign==0 ? 'active' : ''" @click="chose(0)">全部游记</div>
			</div>
			<div class="towrite">
				<img src="../../assets/shot/pen.png" alt="">
				<span @click="toyj">写游记</span>
			</div>
		</div>

		<div class="travellist">
			<div class="tourism" v-for="(item,i) in takelist" :key="i" @click="toinfo(item.id,item.type_id)">
				<div class="img">
					<img :src="item.attachment" alt="" class="image">
				</div>
				<div class="content">
					<h3>{{item.title}}</h3>
					<div class="label" v-html="item.preview">
						
					</div>

					<div class="time">
						<div>
							<img :src="item.avatar" class="img">
							<p style="color: #FF7C00;">{{item.account}}</p>
						</div>
						<div>
							<img src="../../assets/shot/read.png" alt="">
							<p>{{item.look_number}}人阅读</p>
						</div>
						<div>
							<img src="../../assets/shot/sc1.png" alt="">
							<p>{{item.collectionCount}}人收藏</p>
						</div>

					</div>

				</div>
			</div>
			<!-- 分页 -->
			<div class="page">
				<el-pagination
				small
				layout="prev, pager, next"
				:total="total"
				@current-change="handleCurrentChange"
				:page-size="pageSize"
				
				>
				</el-pagination>
			</div>
		</div>

	</div>
	<myfooter></myfooter>
	<myright></myright>

</div>
	
</template>

<script>
import myheader from '../../components/header.vue'
import myfooter from '../../components/footer.vue'
import myright from '../../components/right.vue'

import {teamerListAPI,takeListAPI,BankList2API} from "@/api/trivialShot"
export default {
components: {
    myheader, myfooter, myright
  },
  data () {
    return {
		sign:1,
		teamerlist:[],
		takelist:[],
 		imgs: [],
		first:{},
		total:0,
		page:1,
		pageSize:6
    }
  },
  created () {
	  this.getlist()
	  this.getteamerlist()
	  this.gettakelist(1)
  },
  methods: {
	 
    toinfo (id,tid) {
		if (tid==1) {
			this.$router.push({ path: "/imginfo", query: {id} });
		} else {
			this.$router.push({ path: "/videoinfo", query: {id} });
		}
		
    },
	toyj(){
		
        if (!localStorage.getItem('token')) {
			this.$message("请先登录")
          return false
        } 
		this.tourl('/uptrival')
	},
	chose(i){
		this.sign=i
		this.page=1
		this.gettakelist(i)
	},
	 handleCurrentChange: function(currentPage){
           this.page=currentPage
		   
           this.gettakelist(this.sign)
       },
	getteamerlist(){
		teamerListAPI().then(res=>{
			var list=res.cateList
			var n=""
			for (const i in list) {
				if (list[i].is_month_star==1) {
					this.first=list[i]
					break
					n=i
				}
			}
			list.splice(n,1)
			this.teamerlist=list
		})
	},
	gettakelist(i){
		var obj={
			is_top:i,
			page:this.page,
			pageSize:this.pageSize
		}
		takeListAPI(obj).then(res=>{
			this.takelist=res.cateList
			this.total=res.rowsCount
		})
	},
	tourl(url){
		 this.$router.push({ path: url })
	},
	 tourl2(item){
	   switch(item.type_id){
			case 1:
				this.todetails(item.goods_id)
			break;
			case 2:
				 this.$router.push({ path: '/bannerinfo',query:{id:item.id} })
			break;
			case 3:
				this.toimginfo(item.goods_id,item.type_id)
			break;
		}
   },
   todetails(id){
		this.$router.push({ path: '/details',query:{id} })
	},
	toimginfo(id,tid){
		if (tid==1) {
			this.$router.push({ path: "/imginfo", query: {id} });
		} else {
			this.$router.push({ path: "/videoinfo", query: {id} });
		}
		
	},
	getlist(){
		BankList2API().then(res=>{
			this.imgs=res.cateList
		})
	}
  }
}
</script>

<style lang="scss" scoped>
*{
	box-sizing: border-box;
}
.main{

	width: 1200px;
	margin: 20px auto;
	.imglist{
		display: flex;
		.first{
			width: 285px;
			height: 275px;
			opacity: 1;			
			border-radius: 6px;
			position: relative;
			margin-right: 15px;
			text-align: center;
			
			.firstimg{
				
				overflow: hidden;
				img{
					width: 285px;
					height: 275px;
					border-radius: 6px;
					width: 100%;
				}
			}
			.tipimg{
				position: absolute;
				top: 20px;
				left: -20px;
				z-index: 6;
			}
			p{
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				text-align: center;
				font-size: 20px;
				z-index: 2;
				color: rgba(255,255,255,0);
				transition: color .3s linear;
			}
			.firstbg{			
				position: absolute;
				border-radius: 6px;
				top: 0;
				left: 0;
				width: 100%;
				height: 0px;
				transition: all .6s linear;
			}
		}
		.first:hover .firstbg{
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.50);
			cursor: pointer;
		}
		.first:hover p{
			color: rgba(255,255,255,1);
		}
		.otherimg{
			flex: 1;
			display: flex;

			// justify-content: space-between;
			flex-wrap: wrap;
			div{
				width: 138px;
				height: 138px;
				// margin-left: 12px;
				margin-right: 12px;
				border-radius: 6px;
				margin-bottom: 20px;
				img{
					border-radius: 6px;
					width: 138px;
				}

			}
		}
		.last{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			background: rgba(255,124,0,0.10);
			border-radius: 5px;
			div{
				width: 90px;
				height: 50px;
				p{
					text-align: center;
					color: #6d380c;
					line-height: 26px;
					font-size: 12px;
				}
			}
		}
		.imgbg{
			overflow: hidden;
			position: relative;
			p{
				position: absolute;
				top: 50%;
				left: 0;
				width: 100%;
				text-align: center;
				font-size: 20px;
				z-index: 2;
				color: rgba(255,255,255,0);
				transition: color .3s linear;
			}
			.bg{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 0px;
				transition: all .6s linear;

			}
		}

		.imgbg:hover .bg{
			width: 100%;
			height: 100%;
			background: rgba(0,0,0,0.50);
			cursor: pointer;
		}
		.imgbg:hover p{
			color: rgba(255,255,255,1);
		}

	}

	.trivalwrite{
		display: flex;
		justify-content: space-between;
		.chose{
			display: flex;
			margin-top: 20px;
			div{
				cursor: pointer;
				width: 140px;
				height: 45px;
				font-size: 16px;
				font-weight: 500;
				line-height: 45px;
				text-align: center;
				color: #666666;
				border-bottom: 2px solid #cccccc;
				font-weight: bold;
			}
			.active{
				border-bottom: 2px solid #FF7C00;
				color: #ff7c00;
			}

		}
		.towrite{
			cursor: pointer;
			width: 138px;
			height: 45px;
			opacity: 1;
			background: #ff7c00;
			border-radius: 8px;
			line-height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 16px;
			span{
				color: #fff;
				margin-left: 2px;
			}

		}

	}

	.travellist{
		// background-color: #fff;
		border-radius: 10px;
		// width:930px;
		min-height: 500px;
		margin-top: 20px;
		padding: 20px 20px;
		box-sizing: border-box;
		.tourism{
			display: flex;
			margin-bottom: 20px;
			.img{
				position: relative;
				border-radius: 5px;
				width: 300px;
				height: 150px;
				overflow: hidden;
				.image{
					width: 100%;
					min-height: 160px;
					border-radius: 5px 5px 0 0;
				}

			}

			.content{
				flex: 1;
				padding-left: 20px;
				h3{
					font-size: 17px;
					font-weight: 600;
					line-height: 28px;
					height: 40px;
					line-height: 30px;
					// background-color: red;
				}
				.label{
					font-size: 12px;
					height: 60px;
					// background-color: red;
					font-weight: 400;
					text-align: left;
					color: #333333;
					line-height: 17px;
					overflow: hidden;
				}

				.time{

					display: flex;
					>div{
						margin-top: 20px;
						display: flex;
						align-items: center;
						margin-right: 15px;
						img{
							width: 16px;
							height: 17px;
						}
						.img{
							width: 30px;
							height: 30px;
							border-radius: 50%;
						}
						p{
							font-size: 13px;
							margin-left: 5px;
							color: #666;
							line-height: 25px;

						}
						.orange{
							color:#FF4C12
						}
					}
				}

			}
		}
		.page{
			width: 120px;

			display: flex;
			height: 25px;
			margin: 45px auto 15px;
			p{

				margin: 0 8px;
				font-size: 14px;
				text-align: center;
				color: #333333;
				line-height: 25px;
			}
		}
	}

}

</style>
<style lang="scss">
.banner{
	margin-top: 1px;
	#slider{
	    position: relative;
	    width: 100%;
		min-width: 1200px;
	    overflow: hidden;
	    height: 400px;
	  
	  .el-carousel__container{
		  width: 100%;
		  
	  }
	  ul{
		.el-carousel__button{

			height:8px;
			border-radius: 4px;
			opacity:1;
			width:30px;
			background-color: #fff;
		}
		.is-active .el-carousel__button{
			background-color: #ff7c00;
			width:55px;
		}
	}
	  .slider-imgcontainer {
	    position: absolute;
	    left: 0;
	    width: 400%;
	    height: 380px;
	    transition: left .5s linear;
	  }
	  
	  .pic{
		  min-height: 400px;
		    width: 100%;
	    // width: 25%;
			min-width: 1200px;		
	  }
		
	}
}
</style>
